import React from "react";
import ReactDOM from "react-dom";
import InputReactWithBootstrap from "../inputReactWithBootstrap.js";
import Validation from "react-validation";
import { isPasswordStrengthOk } from "../../validator";
require("es6-promise").polyfill();

export default class CompleteResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMail: true,
      password: "",
      passwordFocus: "",
      passwordValid: "",
      repeatPassword: "",
      repeatPasswordFocus: "",
      repeatPasswordValid: "",
      errorMessage: "",
      infoMessage: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // Password input events
    this.handlePasswordUnfocus = this.handlePasswordUnfocus.bind(this);
    this.handlePasswordFocus = this.handlePasswordFocus.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);

    // Repeat Password input events
    this.handleRepeatPasswordFocus = this.handleRepeatPasswordFocus.bind(this);
    this.handleRepeatPasswordChange =
      this.handleRepeatPasswordChange.bind(this);
    this.handleRepeatPasswordUnfocus =
      this.handleRepeatPasswordUnfocus.bind(this);
  }

  render() {
    return (
      <div>
        <section className="page-wrapper">
          <div className="page-wrapper__layout">
            <div className="row">
              <div className="col-md-7">
                <Validation.components.Form
                  ref={(c) => {
                    this.form = c;
                  }}
                  onSubmit={(event) => this.handleSubmit(event)}
                >
                  <div className="margin-bottom-30">
                    <h2>Välj ett nytt lösenord</h2>
                    <p>
                      Ditt nya lösenord måste vara minst 8 tecken varav en stor
                      bokstav samt får lösenordet inte ha förekommit i
                      upprepade lösenordsläckor.
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      {this.state.infoMessage && (
                        <div className="c-alert c-alert--success">
                          <i className="fa fa-check" aria-hidden="true"></i>
                          {this.state.infoMessage}
                        </div>
                      )}
                      {this.state.errorMessage && (
                        <div className="c-alert c-alert--danger">
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          {this.state.errorMessage}
                        </div>
                      )}
                      <div className="c-alert c-alert--info">
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                        ></i>
                        Ditt lösenord ska innehålla minst 8 tecken varav en stor
                        bokstav.
                      </div>
                      <input
                        value={window.completeresetpassword.email}
                        name="email"
                        type="hidden"
                      />
                      <input
                        value={window.completeresetpassword.token}
                        name="token"
                        type="hidden"
                      />
                      <InputReactWithBootstrap
                        onChange={this.handlePasswordChange}
                        onFocus={this.handlePasswordFocus}
                        onBlur={this.handleUnfocus}
                        label="Nytt lösenord"
                        value={this.state.password}
                        type="password"
                        name="password"
                        className="form__input"
                        id="password"
                        focus={this.state.passwordFocus}
                        valid={this.state.passwordValid}
                        validations={["required", "passwordStrength"]}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      <InputReactWithBootstrap
                        onChange={this.handleRepeatPasswordChange}
                        onFocus={this.handleRepeatPasswordFocus}
                        onBlur={this.handleUnfocus}
                        label="Upprepa nytt lösenord"
                        value={this.state.repeatPassword}
                        type="password"
                        name="passwordConfirm"
                        className="form__input"
                        id="passwordConfirm"
                        focus={this.state.repeatPasswordFocus}
                        valid={this.state.repeatPasswordValid}
                        validations={["required", "password"]}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 complete-reset-password-page__button-wrapper">
                      <a
                        href={window.completeresetpassword.apiurl}
                        className="btn-secondary"
                        tabIndex="0"
                      >
                        Tillbaka
                      </a>
                      <input
                        type="submit"
                        value="Byt lösenord"
                        className="btn uhm-petrol"
                        tabIndex="0"
                      />
                    </div>
                  </div>
                </Validation.components.Form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  //Handlers --------------------------------------------------------------------------------------------------

  handlePasswordChange(inputPassword) {
    this.setState({
      password: inputPassword.target.value,
    });
    if (this.state.password && !this.state.passwordFocus) {
      this.setState({
        passwordValid: "-valid",
      });
    } else {
      this.setState({
        passwordValid: "",
      });
    }
  }

  handlePasswordFocus() {
    this.setState({
      repeatPasswordFocus: "-focus",
    });
  }

  handleRepeatPasswordChange(inputRepeatPassword) {
    this.setState({
      repeatPassword: inputRepeatPassword.target.value,
    });
    if (this.state.repeatPassword && !this.state.repeatPasswordFocus) {
      this.setState({
        repeatPasswordValid: "-valid",
      });
    } else {
      this.setState({
        repeatPasswordValid: "",
      });
    }
  }

  handleRepeatPasswordFocus() {
    this.setState({
      repeatPasswordFocus: "-focus",
    });
  }

  handlePasswordUnfocus() {
    this.setState({
      passwordFocus: "",
    });
  }

  handleRepeatPasswordUnfocus() {
    this.setState({
      repeatPasswordFocus: "",
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (
      isPasswordStrengthOk(this.state.password) &&
      this.state.password === this.state.repeatPassword
    ) {
      fetch(window.completeresetpassword.apiurl + "completepasswordreset", {
        body: JSON.stringify({
          email: window.completeresetpassword.email,
          password: this.state.password,
          repeatPassword: this.state.repeatPassword,
          token: window.completeresetpassword.token,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          RequestVerificationToken: window.completeresetpassword.antiforgery,
        },
        credentials: "include",
        method: "POST",
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.isError || result.status >= 300) {
            this.setState({
              errorMessage: result.error
                ? result.error
                : `Ett okänt fel uppstod. Försök igen om en liten stund.`,
              infoMessage: "",
            });
          } else {
            window.location.href = result.redirectTo;
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            errorMessage: `Ett okänt fel uppstod. Försök igen om en liten stund.`,
            infoMessage: "",
          });
        });
    }
  }
}

const completeResetPasswordDiv = document.getElementById(
  "completeresetpassword"
);
if (completeResetPasswordDiv) {
  ReactDOM.render(<CompleteResetPassword />, completeResetPasswordDiv);
}
