import React from "react";
import ReactDOM from "react-dom";
import InputReactWithBootstrap from "../inputReactWithBootstrap.js";
import Validation from "react-validation";
import { isEmail } from "../../validator";

require("es6-promise").polyfill();

export default class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMail: true,
      mail: window.login.email || "",
      mailFocus: "",
      mailValid: "",
      password: "",
      passwordFocus: "",
      passwordValid: "",
      errorMessage: "",
      errorLoginMessage: "",
      infoMessage: "",
    };

      this.handleMailChange = this.handleMailChange.bind(this);
      this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLogInClick = this.handleLogInClick.bind(this);
    this.handleMailFocus = this.handleMailFocus.bind(this);
    this.handlePasswordFocus = this.handlePasswordFocus.bind(this);
    this.handleUnfocus = this.handleUnfocus.bind(this);
  }

  render() {
    return (
      <div>
        <section className="page-wrapper">
          <div className="page-wrapper__layout">
            <div className="row">
              <div className="col-md-7">
                <Validation.components.Form
                  ref={(c) => {
                    this.form = c;
                  }}
                  onSubmit={this.handleLogInClick}
                >
                  <div className="margin-bottom-30">
                    <h2>Logga in som annonsör</h2>
                    <p>
                      Här loggar du in med dina inloggningsuppgifter och
                      redigerar annonser för din organisation/myndighet. Är du
                      ny användare som saknar inloggningsuppgifter fyller du i
                      formuläret för att registrera dig.
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      {this.state.errorMessage && (
                        <div className="c-alert c-alert--danger">
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `<p>${this.state.errorMessage}<p>`,
                            }}
                          />
                        </div>
                      )}
                      {window.login.errorList &&
                        window.login.errorList.length > 0 && (
                          <div className="c-alert c-alert--info">
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>
                            {window.login.errorList.map((message, index) => {
                              return <span key={index}>{message}</span>;
                            })}
                          </div>
                        )}
                      <InputReactWithBootstrap
                        onChange={this.handleMailChange}
                        onFocus={this.handleMailFocus}
                        onBlur={this.handleUnfocus}
                        label="E-post"
                        value={this.state.mail}
                        type="input"
                        name="email"
                        className="form__input"
                        id="mail"
                        focus={this.state.mailFocus}
                        valid={this.state.mailValid}
                        validations={["required", "email"]}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-7">
                      <InputReactWithBootstrap
                        onChange={this.handlePasswordChange}
                        onFocus={this.handlePasswordFocus}
                        onBlur={this.handleUnfocus}
                        label="Lösenord"
                        value={this.state.password}
                        type="password"
                        name="password"
                        className="form__input"
                        id="password"
                        focus={this.state.passwordFocus}
                        valid={this.state.passwordValid}
                        validations={["required"]}
                      />
                      {window.login.antiforgery && (
                        <input
                          value={window.login.antiforgery}
                          name="__RequestVerificationToken"
                          type="hidden"
                        />
                      )}

                      {window.login.returnUrl && (
                        <input
                            value={window.login.returnUrl}
                            name="returnUrl"
                            type="hidden"
                          />
                      )}

                      <a
                        href={`${window.login.apiurl}resetpassword`}
                        className="login-page__link login-page__link--forgot-password"
                      >
                        Glömt lösenord?
                      </a>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-12">
                      <div id="login">
                        <input
                          type="submit"
                          value="Logga in"
                          className="btn uhm-petrol"
                          tabIndex="0"
                        />
                      </div>
                      <div className="login-page__link--new-user">
                        <a
                          href={`${window.login.apiurl}register`}
                          className="login-page__link"
                        >
                          Ny användare? Registrera dig här.
                        </a>
                      </div>
                    </div>
                  </div>
                </Validation.components.Form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  //Handlers --------------------------------------------------------------------------------------------------
  handleMailChange(inputMail) {
    this.setState({
      mail: inputMail.target.value,
    });
    if (this.state.mail && !this.state.mailFocus) {
      this.setState({
        mailValid: "-valid",
      });
    } else {
      this.setState({
        mailValid: "",
      });
    }
  }
 
  handlePasswordChange(inputPassword) {
    this.setState({
      password: inputPassword.target.value,
    });
    if (this.state.password && !this.state.passwordFocus) {
      this.setState({
        passwordValid: "-valid",
      });
    } else {
      this.setState({
        passwordValid: "",
      });
    }
  }
  handleLogInClick(event) {
    event.preventDefault();

    if (isEmail(this.state.mail) && this.state.password) {
      this.isUser(this.state.mail, this.state.password);
    } else {
      this.form.validateAll();
    }
    }

  handleMailFocus() {
    this.setState({
      mailFocus: "-focus",
      passwordFocus: "",
    });
  }

  handlePasswordFocus() {
    this.setState({
      passwordFocus: "-focus",
      mailFocus: "",
    });
  }

  handleUnfocus() {
    this.setState({
      mailFocus: "",
      passwordFocus: "",
    });
  }

  handleInfo(infoMessage) {
    this.setState({
      infoMessage,
    });
  }
  //db --------------------------------------------------------------------------------------------------

  isUser(user, password) {
    fetch(window.login.apiurl + "Validate", {
      body: JSON.stringify({
        email: user,
        password: password,
        returnUrl: window.login.returnUrl,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        RequestVerificationToken: window.login.antiforgery
      },
      credentials: "include",
      method: "POST",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.isError || result.status >= 300) {
          this.setState({
            errorMessage: result.error
              ? result.error
              : `Ett okänt fel uppstod. Försök igen om en liten stund.`,
          });
        } else {
          if (window.dataLayer && window.dataLayer.push) {
            window.dataLayer.push({
              event: "lovLogin",
            });
          }
          window.location.href = result.redirectTo;
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: `Ett okänt fel uppstod. Försök igen om en liten stund.`,
        });
      });
  }
}

const loginDiv = document.getElementById("login");
if (loginDiv) {
  ReactDOM.render(<LogIn />, loginDiv);
}
